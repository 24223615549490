import { createStore } from "zustand/vanilla";
import { persist } from "zustand/middleware";

export type WorkspaceState = {
  currentWorkspaceId: string | null;
  _hasHydrated: boolean;
};

export type WorkspaceActions = {
  setCurrentWorkspaceId: (workspaceId: string) => void;
  setHasHydrated: (hasHydrated: boolean) => void;
};

export type WorkspaceStore = WorkspaceState & WorkspaceActions;

export const createWorkspaceStore = () => {
  return createStore<WorkspaceStore>()(
    persist(
      (set, get) => ({
        currentWorkspaceId: get()?.currentWorkspaceId || null,
        setCurrentWorkspaceId: (workspaceId: string) =>
          set(() => ({ currentWorkspaceId: workspaceId })),

        // for reacting when we're in sync with localStorage on the client
        // we shouldn't show data from the store until the hydration finished
        _hasHydrated: false as boolean,
        setHasHydrated: (hasHydrated: boolean) => {
          set({
            _hasHydrated: hasHydrated,
          });
        },
      }),
      {
        name: "workspace-store",
        onRehydrateStorage: () => (state) => {
          state?.setHasHydrated(true);
        },
      },
    ),
  );
};
