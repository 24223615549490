import { AuthOptions } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";

import { UserSchema } from "@setmixer-platform/core/user";
import { CORE_API_URL, NEXTAUTH_SECRET } from "./env";

export const authOptions: AuthOptions = {
  providers: [
    CredentialsProvider({
      name: "Credentials",
      credentials: {
        accessToken: { label: "token", type: "text" },
      },
      async authorize(credentials, req) {
        if (!credentials?.accessToken) {
          return null;
        }

        const response = await fetch(`${CORE_API_URL}/api/users/me`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${credentials.accessToken}`,
          },
        });
        const user: UserSchema = await response.json();

        // If no error and we have user data, return it
        if (response.ok && user) {
          return { ...user, accessToken: credentials.accessToken };
        }
        return null;
      },
    }),
  ],
  session: {
    strategy: "jwt",
    maxAge: 60 * 60 * 24 * 90, // 90 days
  },
  callbacks: {
    /**
     * JWT token provisioned by `next-auth` will be used solely by the frontend to persist the user session and protect routes.
     * It also offers handy hooks for managing user session.
     * Inside this token is included `accessToken` that is required for backend API requests.
     */
    jwt: async ({ token, user }) => {
      if (user) {
        token.user = user;
      }

      return token;
    },
    session: async ({ session, token }) => {
      session.user = token.user;
      return session;
    },
    redirect: async ({ url, baseUrl }) => {
      // Allow relative redirect URLs on things like signin/signout
      if (url.startsWith("/")) return `${baseUrl}${url}`;
      return baseUrl;
    },
  },
  secret: NEXTAUTH_SECRET,
  pages: {
    signIn: "/",
    signOut: "/logout",
  },
};
