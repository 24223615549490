import { type ReactElement } from "react";
import { Dialog } from "@headlessui/react";

export default function PopupDialog({
  isOpen,
  setIsOpen,
  title,
  children,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  children: ReactElement;
}) {
  return (
    // <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={`
        fixed inset-0 z-dialog
        bg-black/90
        flex items-center justify-center
        p-4 md:p-8
      `}
    >
      <Dialog.Panel
        className={`
          bg-gradient-to-b from-gray-800 to-gray-900
          border border-solid border-gray-700
          overflow-hidden
          flex flex-col gap-8
          p-8
          w-full max-w-xl
          rounded-sm
        `}
      >
        <Dialog.Title
          as="h3"
          className={`
            font-semibold
            text-lg md:text-2xl
            text-gray-100
          `}
        >
          {title}
        </Dialog.Title>

        <>{children}</>
      </Dialog.Panel>
      {/* </Transition.Child> */}
    </Dialog>
    // </Transition>
  );
}
