import { type ReactElement, type ReactNode, useState } from "react";
import { createContext, useContext } from "react";
import {
  type WebAppRecordingSchema,
  type WebAppRecordingLocationSchema,
} from "@setmixer-platform/core/webapp/recording";

const AudioPlayerContext = createContext<{
  ref: React.RefObject<HTMLAudioElement> | null;
  setRef: (ref: React.RefObject<HTMLAudioElement> | null) => void;
  recording: WebAppRecordingSchema | null;
  setRecording: (recording: WebAppRecordingSchema | null) => void;
  location: WebAppRecordingLocationSchema | null;
  setLocation: (location: WebAppRecordingLocationSchema | null) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  currentTime: number;
  setCurrentTime: (currentTime: number) => void;
  totalTime: number;
  setTotalTime: (totalTime: number) => void;
  seekTime: number;
  setSeekTime: (seekTime: number) => void;
  volume: number;
  setVolume: (volume: number) => void;
}>({
  ref: null,
  setRef: () => {},
  recording: null,
  setRecording: () => {},
  location: null,
  setLocation: () => {},
  isPlaying: false,
  setIsPlaying: () => {},
  currentTime: 0,
  setCurrentTime: () => {},
  totalTime: 0,
  setTotalTime: () => {},
  seekTime: 0,
  setSeekTime: () => {},
  volume: 1,
  setVolume: () => {},
});

export function AudioPlayerProvider({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  const [ref, setRef] = useState<React.RefObject<HTMLAudioElement> | null>(
    null,
  );
  const [recording, setRecording] = useState<WebAppRecordingSchema | null>(
    null,
  );
  const [location, setLocation] =
    useState<WebAppRecordingLocationSchema | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [volume, setVolume] = useState(1);

  return (
    <AudioPlayerContext.Provider
      value={{
        ref,
        setRef,
        recording,
        setRecording,
        location,
        setLocation,
        isPlaying,
        setIsPlaying,
        currentTime,
        setCurrentTime,
        totalTime,
        setTotalTime,
        seekTime,
        setSeekTime,
        volume,
        setVolume,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
}

export const useAudioPlayerContext = () => useContext(AudioPlayerContext);
