import { useEffect, type ReactElement, type ReactNode } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";

import Head from "next/head";
import { Inter } from "next/font/google";

import { trpcHooks } from "@/lib/trpc";

import "@/public/globals.css";
import { AudioPlayerProvider } from "@/context/AudioPlayerContext";
import { NotificationProvider } from "@/context/NotificationContext";
import { NowPlayingViewProvider } from "@/context/NowPlayingViewContext";
import { UserProfileProvider } from "@/context/UserProfileContext";
import { VenueStreamPlayerProvider } from "@/context/VenueStreamPlayerContext";
import { DialogProvider } from "@/context/DialogContext";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useRouter } from "next/router";
import { STAGE } from "@/lib/env";
import { WorkspaceProvider } from "@/context/WorkspaceContext";

if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://eu.posthog.com",
    loaded: (posthog) => {
      if (!["dev", "prod"].includes(STAGE)) posthog.debug();
    },
  });
}

const inter = Inter({ subsets: ["latin"] });

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  // We don't wrapt the entire app via `withTRPC` HOC, because we want it to be withing auth context
  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-inter: ${inter.style.fontFamily};
          }

          html {
            font-family: ${inter.style.fontFamily};
          }
        `}
      </style>

      <Head>
        <title>Setmixer</title>
        <meta name="color-scheme" content="dark" key="color-scheme" />
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
          key="viewport"
        />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" href="/static/favicon.ico" sizes="any" />

        {/* OpenGraph */}
        <meta property="og:title" content="Setmixer" key="title" />
        <meta property="og:image" content="/opengraph-image.png" />
        <meta property="og:image:type" content="image/png" />

        {/* iOS */}
        <meta name="apple-mobile-web-app-title" content="Setmixer" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/setmixericon.png"
        />

        {/* Android */}
        <link rel="manifest" href="/manifest.json" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#ffd500" />
      </Head>

      <PostHogProvider client={posthog}>
        <AudioPlayerProvider>
          <VenueStreamPlayerProvider>
            <NowPlayingViewProvider>
              <SessionProvider session={session}>
                <UserProfileProvider>
                  <NotificationProvider>
                    <DialogProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                      <WorkspaceProvider>
                        {getLayout(<Component {...pageProps} />)}
                      </WorkspaceProvider>
                    </DialogProvider>
                  </NotificationProvider>
                </UserProfileProvider>
              </SessionProvider>
            </NowPlayingViewProvider>
          </VenueStreamPlayerProvider>
        </AudioPlayerProvider>
      </PostHogProvider>
    </>
  );
}

export default trpcHooks.withTRPC(MyApp);
