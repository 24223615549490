import type { AppRouter } from "@setmixer-platform/functions/trpc/server";
import {
  TRPCClientError,
  createTRPCProxyClient,
  httpBatchLink,
} from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { createServerSideHelpers } from "@trpc/react-query/server";
import { GetServerSidePropsContext } from "next";
import { getServerSession } from "next-auth";
import { getSession } from "next-auth/react";
import { TRPC_API_URL } from "./env";
import { authOptions } from "./next-auth";

export const trpcHooks = createTRPCNext<AppRouter>({
  config(_) {
    return {
      links: [
        httpBatchLink({
          url: TRPC_API_URL,

          async headers() {
            const accessToken = (await getSession())?.user.accessToken;
            return {
              Authorization: `Bearer ${accessToken}`,
            };
          },
        }),
      ],
    };
  },
  /**
   * @link https://trpc.io/docs/v11/ssr
   * Whether queries should be prefetched in getInitialProps
   **/
  ssr: false,
});

/**
 * For getServerSideProps only
 * Cotnext passing should not be needed in NextJs app router and RSCs
 */
export const getSSRTrpcClient = (ctx: GetServerSidePropsContext) => {
  const proxyClient = createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url: TRPC_API_URL,
        async headers() {
          const accessToken = (
            await getServerSession(ctx.req, ctx.res, authOptions)
          )?.user.accessToken;
          return {
            Authorization: `Bearer ${accessToken}`,
          };
        },
      }),
    ],
  });
  return createServerSideHelpers({
    client: proxyClient,
  });
};

export function isTRPCClientError(
  cause: unknown
): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}
