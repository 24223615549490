import { type ReactElement, type ReactNode, useState } from "react";
import { createContext, useContext } from "react";

const NowPlayingViewContext = createContext<{
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}>({
  isExpanded: false,
  setIsExpanded: () => {},
});

export function NowPlayingViewProvider({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <NowPlayingViewContext.Provider
      value={{
        isExpanded,
        setIsExpanded,
      }}
    >
      {children}
    </NowPlayingViewContext.Provider>
  );
}

export const useNowPlayingViewContext = () => useContext(NowPlayingViewContext);
