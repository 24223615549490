export const isDebug = () =>
  (process.env.DEBUG || process.env.NEXT_PUBLIC_DEBUG)
    ?.toString()
    .toLowerCase() === "true";

export const CORE_API_URL = process.env.NEXT_PUBLIC_TRPC_URL || "";
export const TRPC_API_URL = `${CORE_API_URL}/api/trpc`;
export const AUTH_URL = process.env.NEXT_PUBLIC_AUTH_URL || "";
export const WEBAPP_URL = process.env.NEXT_PUBLIC_WEBAPP_URL || "";
export const STAGE = process.env.NEXT_PUBLIC_STAGE || "dev";
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET || "show must go on";

export const NEXT_PUBLIC_INSTAGRAM_CLIENT_ID =
  process.env.NEXT_PUBLIC_INSTAGRAM_CLIENT_ID || "";
export const NEXT_PUBLIC_INSTAGRAM_REDIRECT_URI =
  process.env.NEXT_PUBLIC_INSTAGRAM_REDIRECT_URI || "";
