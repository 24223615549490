"use client";
import { trpc } from "@/lib/app-router-trpc/trpc";
import { WorkspaceStore, createWorkspaceStore } from "@/store/workspace";
import { useSession } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import {
  type ReactNode,
  createContext,
  useRef,
  useContext,
  useEffect,
} from "react";
import { useStore } from "zustand";

export type WorkspaceStoreApi = ReturnType<typeof createWorkspaceStore>;

export const WorkspaceContext = createContext<WorkspaceStoreApi | undefined>(
  undefined,
);

export interface WorkspaceProviderProps {
  children: ReactNode;
}

export const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const storeRef = useRef<WorkspaceStoreApi>();
  if (!storeRef.current) {
    storeRef.current = createWorkspaceStore();
  }
  const { data: session } = useSession();
  const trpcUtils = trpc.useUtils();
  const state = storeRef.current.getState();
  const currentUser = session?.user;
  const posthog = usePostHog();

  useEffect(() => {
    if (currentUser) {
      posthog.identify(currentUser.email, {
        email: currentUser.email,
        userId: currentUser.id,
      });
    }
  }, [currentUser]);

  const setCurrentWorkspace = async () => {
    if (!currentUser) {
      return;
    }
    const workspaces = await trpcUtils.webapp.users.workspaces.fetch();
    if (!state.currentWorkspaceId && state._hasHydrated) {
      const firstWorkspaceId = workspaces?.[0]?.id;
      if (firstWorkspaceId) {
        storeRef.current?.setState({
          currentWorkspaceId: firstWorkspaceId,
        });
      }
    }
  };

  useEffect(() => {
    setCurrentWorkspace();
  }, [state.currentWorkspaceId]);

  return (
    <WorkspaceContext.Provider value={storeRef.current}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = <T,>(
  selector: (store: WorkspaceStore) => T,
): T => {
  const workspaceContext = useContext(WorkspaceContext);

  if (!workspaceContext) {
    throw new Error(`useWorkspace must be used within WorkspaceStoreProvider`);
  }

  return useStore(workspaceContext, selector);
};
