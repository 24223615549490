import {
  type ReactElement,
  type ReactNode,
  useEffect,
  useState,
  useContext,
  createContext,
  useCallback,
} from "react";
import { Duration } from "luxon";

export type VenueStreamData = {
  server_name: string; // Used for the location code
  listenurl: string;
  stream_start_iso8601: string;
  "content-type": string;
};

const VenueStreamPlayerContext = createContext<{
  findSource: (code: string) => VenueStreamData | undefined;
  source: VenueStreamData | undefined;
  setSource: (source: VenueStreamData | undefined) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  elapsedTime: string;
  volume: number;
  setVolume: (volume: number) => void;
}>({
  findSource: () => {
    return undefined;
  },
  source: undefined,
  setSource: () => {},
  isPlaying: false,
  setIsPlaying: () => {},
  elapsedTime: "",
  volume: 1,
  setVolume: () => {},
});

export function VenueStreamPlayerProvider({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  const [audioElement, setAudioElement] = useState<HTMLAudioElement>();
  const [streamingPlayer, setStreamingPlayer] = useState<any>(null);

  const [allSources, setAllSources] = useState<VenueStreamData[]>([]);
  const [source, setSource] = useState<VenueStreamData>();

  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("");
  const [volume, setVolume] = useState(1);

  const findSource = useCallback(
    (code: string) => {
      if (
        !Array.isArray(allSources) ||
        allSources.length === 0 ||
        code.length === 0
      ) {
        return undefined;
      }

      const matchingSource = allSources.find(
        (s: any) =>
          s &&
          typeof s === "object" &&
          typeof s.listenurl === "string" &&
          s.listenurl.toLowerCase().indexOf(`/${code.toLowerCase()}`) >= 0,
      );

      return matchingSource;
    },
    [allSources],
  );

  const updateElapsedTime = useCallback(() => {
    if (!source?.stream_start_iso8601) {
      return;
    }

    const streamStartTime = new Date(source.stream_start_iso8601).getTime();
    const currentTime = new Date().getTime();
    const duration = Duration.fromMillis(currentTime - streamStartTime);

    setElapsedTime(duration.toFormat("h:mm:ss"));
  }, [source, setElapsedTime]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const url = `${process.env.NEXT_PUBLIC_STREAM_METADATA_URL}`;
        const response = await fetch(url);
        const json = await response.json();

        const sources = Array.isArray(json?.icestats?.source)
          ? json.icestats.source
          : [];

        setAllSources(sources);
      } catch (e: any) {
        console.error(e);
      }
    };

    fetchMetadata();

    setAudioElement(new Audio());
  }, []);

  useEffect(() => {
    let player = streamingPlayer;

    const initPlayer = async () => {
      if (!source) {
        return;
      }

      if (!audioElement) {
        return;
      }

      if (player) {
        await player.stop();
        player.detachAudioElement();
      }

      const IcecastMetadataPlayer = (await import("icecast-metadata-player"))
        .default;

      const httpsUrl = source.listenurl
        .replace("http", "https")
        .replace(":80", "");
      player = new IcecastMetadataPlayer(httpsUrl, {
        onStream: () => {
          updateElapsedTime();
        },
        onStreamEnd: () => {
          setIsPlaying(false);
          setElapsedTime("");
        },
        metadataTypes: [],
        audioElement,
      });

      setStreamingPlayer(player);
    };

    initPlayer();

    return () => {
      if (player) {
        player.stop().then(() => {
          player.detachAudioElement();
        });
      }
    };
  }, [source, audioElement, updateElapsedTime]);

  useEffect(() => {
    if (!audioElement) {
      return;
    }

    if (!isPlaying) {
      streamingPlayer?.stop();
      return;
    }

    if (isPlaying) {
      audioElement.muted = true;
      streamingPlayer?.play();
      audioElement.muted = false;
    }
  }, [audioElement, isPlaying, streamingPlayer]);

  return (
    <VenueStreamPlayerContext.Provider
      value={{
        findSource,
        source,
        setSource,
        isPlaying,
        setIsPlaying,
        elapsedTime,
        volume,
        setVolume,
      }}
    >
      {children}
    </VenueStreamPlayerContext.Provider>
  );
}

export const useVenueStreamPlayerContext = () =>
  useContext(VenueStreamPlayerContext);
